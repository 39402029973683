// mini softPage

import { strict } from "assert";
import { type } from "os";

declare global{
    interface HTMLElement{
        /**
         * Стилизовать
         * @param style 
         */
        styles(style: object) : HTMLElement;
        tie(target: HTMLElement) : HTMLElement;
    }	
}
HTMLElement.prototype.styles = function(style: object, timeFrame: boolean | number = false){    

    if (timeFrame){

        let self = this;
        setTimeout(function(){

            for (let key in style) {
                self.style[key] = style[key];
            }
        }, typeof timeFrame == typeof 0 ? timeFrame as number : 40);
    }
    else for (let key in style) this.style[key] = style[key];

    return this;
}
HTMLElement.prototype.tie = function(target: HTMLElement){

    target.parentElement.insertBefore(this, target);
    return this;
}



let vom : {
    add?: Function,
    create?: Function,
    encode?: Function
} = {}

vom.add = function(container : string| HTMLElement, elem:string|HTMLElement, cls?:string):HTMLElement
{
    if (typeof container == 'string') container = document.querySelector(container) as HTMLElement;
    
    if (typeof elem == 'string'){
        
        elem = document.createElement(elem);
        if (cls) elem.className = cls;
        
    }
    
    container.appendChild(elem);
    
    return elem;		
};


vom.create = function(tagname: string, attrs: object):HTMLElement{
    var elem = document.createElement(tagname);
    for (var attr in attrs){
        elem[attr]=attrs[attr];
    }
    return elem;
}

vom.encode = function(jsonObject: object): string{
    
    let params: string[] = [];
    for (const key in jsonObject) {
                
        params.push(key + '=' + jsonObject[key]);
    }        
    return '?'+params.join('&');
}

export default vom;